<template>
  <div>
    <div id="ag-grid-demo">
      <vx-card>
        <div class="flex flex-wrap justify-between items-center form-title">
          <h4 class="mb-3 sm:mb-0">COUNTRY LIST</h4>
          <div class="flex flex-wrap justify-end items-center">
            <div
              class="
                flex flex-wrap
                items-center
                justify-between
                ag-grid-table-actions-right
              "
            >
              <div class="sm:pr-2">
                <Multiselect
                  class="mb-4 md:mb-0 mr-4"
                  style="width: 100px"
                  v-model="listType"
                  :options="['Active', 'All']"
                  @select="updateSelected"
                  selectLabel=""
                  deselectLabel=""
                  selectedLabel=""
                  openDirection="bottom"
                />
              </div>

              <vs-input
                class="mb-4 md:mb-0 mr-4"
                v-model="searchQuery"
                @input="onChange"
                placeholder="Search..."
              />
            </div>
          </div>
        </div>
        <ag-grid-vue
          :gridOptions="gridOptions"
          class="ag-theme-material w-100 my-4 ag-grid-table"
          :columnDefs="columnDefs"
          :defaultColDef="defaultColDef"
          :rowData="countries"
          rowSelection="multiple"
          :animateRows="true"
        />
        <vs-pagination
          :total="totalPages"
          :max="maxPageNumbers"
          v-model="currentPage"
        />
      </vx-card>
    </div>
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import CountryService from "@/services/countryService.js";
import SearchService from "@/services/searchService.js";
import CountryStatus from "./CountryStatus";
import Multiselect from "vue-multiselect/src/Multiselect";
import "@/assets/scss/vuesax/extraComponents/agGridStyleOverride.scss";

export default {
  components: {
    CountryStatus,
    AgGridVue,
    Multiselect,
  },
  watch: {
    "$store.state.windowWidth"(val) {
      if (val <= 576) {
        this.maxPageNumbers = 4;
      }
    },
  },
  computed: {
    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      else return 100;
    },
    currentPage: {
      get() {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
        else return 1;
      },
      set(val) {
        if (val) {
          this.getAllCountryList(val);
          return val;
        } else return 1;
      },
    },
  },

  data() {
    return {
      countries: [],
      listType: "Active",
      searchQuery: "",
      gridOptions: {},
      maxPageNumbers: 7,
      gridApi: null,
      SelectOption: "Select Option",
      defaultColDef: {
        sortable: true,
        editable: false,
        resizable: true,
        suppressMenu: true,
      },
      totalPages: 0,
      columnDefs: [],
    };
  },

  methods: {
    updateSelected(val) {
      if (val === "All") {
        return this.getAllCountryList(this.currentPage, true);
      }
      return this.getAllCountryList(this.currentPage);
    },
    onChange(val) {
      this.$nextTick(() => {
        this.getAllCountryList(1, null, val);
        // this.updateSearchQuery(val);
      });
    },

    updateSearchQuery(val) {
      SearchService.getAllSerchResult(val, "country", null)
        .then((response) => {
          const { data } = response;
          if (data.data.length > 0 && data.Status) {
            this.countries = data.data;
            this.totalPages = data.pagination.last_page;
          } else {
            this.countries = [];
          }
        })
        .catch((error) => {
          this.$vs.notify({
            title: "Data Not Found",
            text: error.message,
            iconPack: "feather",
            icon: "check_box",
            color: "warning",
          });
        });
    },

    getAllCountryList: function(currentPage, all = false, search = null) {
      let payload = {
        page: currentPage ? currentPage : 0,
        show_all: all || this.listType == "All" ? 1 : 0,
        search: search || "",
      };
      CountryService.getAllCountry(payload)
        .then((response) => {
          const { data } = response;
          if (!data.err || data.Status == true) {
            this.countries = data.data || [];
            this.totalPages = data.pagination.last_page;
            this.gridApi.sizeColumnsToFit();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },

  beforeMount() {
    eventBus.$on("refreshCountryTable", (e) => {
      this.getAllCountryList(this.currentPage);
    });

    this.columnDefs = [
      {
        headerName: "Country Name",
        field: "country_name",
        filter: true,
        width: 575,
      },
      {
        headerName: "Country Code",
        field: "country_code",
        filter: true,
        sortable: true,
        width: 250,
      },
      {
        headerName: "Status",
        field: "country_active",
        filter: false,
        width: 150,
        hide: this.archive == "archive" ? true : false,
        cellStyle: { "text-align": "center" },
        cellRendererFramework: CountryStatus,
      },
    ];
  },

  mounted() {
    if (this.checkPermission(["department", "GET"])) {
      this.getAllCountryList(this.currentPage);
    }
    this.gridApi = this.gridOptions.api;
  },
};
</script>
