<template>
  <div>
    <div class="w-full">
      <router-link to="/master">
        <feather-icon icon="ArrowLeftCircleIcon" svgClasses="h-8 w-8" />
      </router-link>
    </div>
    <div class="w-full mb-base">
      <CountryForm />
    </div>

    <div class="w-full mb-base">
      <CountryList />
    </div>
  </div>
</template>

<script>
import CountryForm from "./CountryForm.vue";
import CountryList from "./CountryList.vue";

export default {
  components: {
    CountryForm,
    CountryList,
  },
};
</script>
