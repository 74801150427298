<template>
  <vx-card class="form-title" title="ADD COUNTRY">
    <form>
      <div class="vx-row">
        <div class="vx-col md:w-3/4 mx-auto">
          <div class="vx-row mb-6">
            <div
              class="vx-col sm:w-1/5 w-full text-left sm:text-right align-self-center"
            >
              <h5 class="mb-0">Country Name*</h5>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
              <div class>
                <vs-input
                  v-validate="'required|alpha_spaces'"
                  placeholder="Country Name"
                  name="Country Name"
                  v-model="form.country_name"
                  class="w-full"
                />
                <span
                  class="text-danger text-sm"
                  v-show="errors.has('Country Name')"
                  >{{ errors.first("Country Name") }}</span
                >
              </div>
            </div>
          </div>

          <div class="vx-row mb-6">
            <div class="vx-col sm:w-1/5 w-full text-left sm:text-right">
              <h5 class="mb-0">Country Code*</h5>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
              <div class>
                <vs-input
                  v-validate="'required|alpha'"
                  placeholder="Country Code"
                  name="Country Code"
                  v-model="form.country_code"
                  @input="form.country_code = form.country_code.toUpperCase()"
                  class="w-full"
                />
                <span
                  class="text-danger text-sm"
                  v-show="errors.has('Country Code')"
                  >{{ errors.first("Country Code") }}</span
                >
              </div>
            </div>
          </div>

          <div class="vx-row mb-6">
            <div class="vx-col sm:w-1/5 w-full text-left sm:text-right">
              <h5 class="mb-0">Status</h5>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
              <b-form-checkbox
                value="1"
                unchecked-value="2"
                v-model="form.country_active"
                name="check-button"
                switch
              />
            </div>
          </div>
        </div>
      </div>

      <!-- SUBMIT BUTTON -->
      <div class="vx-row">
        <div class="vx-col mx-auto">
          <vs-button
            :disabled="submitStatus"
            type="filled"
            @click.prevent="submitForm"
            class="mr-3 mb-3 w-full sm:w-auto"
            >Submit</vs-button
          >

          <vs-button
            color="warning"
            type="border"
            class="mb-3 w-full sm:w-auto"
            @click.prevent="clearForm"
            >Reset</vs-button
          >
        </div>
      </div>
    </form>
  </vx-card>
</template>

<script>
import CountryService from "@/services/countryService.js";
import "@/assets/scss/vuesax/extraComponents/agGridStyleOverride.scss";

export default {
  data() {
    return {
      submitStatus: true,
      form: {
        country_name: "",
        country_code: "",
        country_active: "1",
      },
    };
  },

  watch: {
    form: {
      handler: function(newVal, oldVal) {
        let len = Object.keys(this.form).length;
        if (
          len ===
          Object.keys(newVal).filter(
            (x, index) =>
              newVal[x] &&
              newVal[x] !== undefined &&
              String(newVal[x]).trim().length > 0
          ).length
        ) {
          this.submitStatus = false;
        } else {
          this.submitStatus = true;
        }
      },
      deep: true,
    },
  },

  methods: {
    submitForm() {
      this.$validator.validateAll().then((result) => {
        this.$vs.loading();
        if (result) {
          this.submitStatus = true;
          let payload = {
            ...this.form,
          };
          CountryService.addCountry(payload)
            .then((response) => {
              this.$vs.loading.close();
              this.submitStatus = false;
              const { data } = response;
              if (data.Status == true || data.Status == "true") {
                this.$vs.notify({
                  title: "Created!",
                  text: data.Message,
                  iconPack: "feather",
                  icon: "check_box",
                  color: "success",
                });
                this.clearForm();
              } else {
                this.$vs.loading.close();
                this.$vs.notify({
                  title: "Error!",
                  text: data.Message,
                  iconPack: "feather",
                  icon: "check_box",
                  color: "warning",
                });
              }
            })
            .catch((error) => {
              this.$vs.loading.close();
              this.submitStatus = true;
              this.$vs.notify({
                title: "Error!",
                text: error.message,
                iconPack: "feather",
                icon: "check_box",
                color: "warning",
              });
            });
        }
      });
    },

    clearForm: function() {
      Object.keys(this.form).map((item, index) => {
        this.form[item] = null;
      });
      this.form.country_active = "1";
      this.$validator.reset();
    },
  },
};
</script>
